import React from "react"
import get from "lodash/get"

import { useBlogData } from "../../service/blog.service"
import { useBlogCategoryData } from "../../service/blog-category.service"

import BlogLayout from "../../layouts/BlogLayout/BlogLayout"
import BlogListView from "../../views/BlogListView/BlogListView"
import { LANGUAGES } from "../../utils/consts"

const KenticoBlogData = lang => ({
  blogData: useBlogData(lang),
  blogCategoryData: useBlogCategoryData(lang),
})

const BlogPage = ({ blogData, blogCategoryData, blogCategory } = {}) => (
  <BlogLayout>
    <BlogListView
      posts={blogData}
      categories={blogCategoryData}
      blogCategory={blogCategory}
    />
  </BlogLayout>
)

export default props => {
  const blogCategory = get(props, "location.state.blogCategory")
  const data = { ...KenticoBlogData(LANGUAGES.PL), blogCategory }
  return <BlogPage {...data} />
}
